import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'sonner';
// import { MASTER_BASE_URL } from '../../GPI/API/FetchMaster';
// import { acknowledgeNotifications } from '../../GPI/API/Notifications';
import { GlobalDispatchContext, GlobalStateContext } from '../../GlobalContext/GlobalContextProvider';
import { ACTIONS } from '../../GlobalContext/GlobalReducer';
import { Button } from '../Generic/Button/Button';
import { TextArea } from '../Generic/TextArea/TextArea';
import './notification-ack-pop.scss';

interface INotificationBoxProps {
    notification: any,
    remove: (id) => void;
}

export const NotificationBox = (props: INotificationBoxProps) => {

    const { globalState: { popNotificationAlert, user } } = React.useContext(GlobalStateContext);
    const [popVariables, setPopVariables] = React.useState({
        responseClicked: false,
        message: 'Ok'
    });
    const globalDispatcher = React.useContext(GlobalDispatchContext);


    const acknowledge = async () => {
        try {
            setPopVariables({ ...popVariables, responseClicked: true });
            if (popVariables.message) {
                const payload = {
                    notification_id: props.notification.id,
                    gate_number: user.gateNumber,
                    username: user.username,
                    user_id: user.id,
                    comment: popVariables.message
                }
              

            }
        }
        catch (err) {
            toast.error("Error: failed to acknowledge!");
        }
    }
    return (
        <div className={'notification-ack-pop-container'}>
            <div className={'image-container'}>
                <div className={'camera-name'}>
                    Image / File
                </div>
                <div className={'image'}>
                    {/* <img src={props.notification.file ? `${MASTER_BASE_URL}${props.notification.file}` : `/images/login/profileLogo.svg`} /> */}
                </div>
            </div>
            <div className={'message-container'}>
                <div className={'title'}>{props.notification.type} from {props.notification.created_by}</div>
                <div className={'message-title'}>
                    {/* <span>Title</span> */}
                    <span style={{ color: "green" }}>  {props.notification.title} </span>
                </div>
                <div className={'message'}>
                    <br />
                    {/* <span>Message </span> */}
                    <span>{props.notification.message} </span>
                </div>
                {/* <div className={'message-title'}>
                    <span>Created By </span>
                    <span>: {props.notification.created_by}</span>
                </div> */}
                <div>
                    <TextArea
                        label={''}
                        placeholder={'Enter alert message..'}
                        width={'95%'}
                        height={'1rem'}
                        key={'RegisterNewVisitor-AlertMessage'}
                        onChange={(value) => { setPopVariables({ ...popVariables, message: value }) }}
                        value={popVariables.message}
                        isRequired={true}
                        showWarning={popVariables.responseClicked}
                        maxLength={100}
                    />
                </div>
                <div className={'respond-btn-div'}>
                    <Button
                        class='medium-btn-container'
                        bgcolor={'#4D4D33'}
                        textcolor={'white'}
                        width={'fit-content'}
                        text={'Respond'}
                        onClick={acknowledge}
                    />
                </div>
            </div>
        </div>
    );
};
