import * as React from 'react';
import './configs-management.scss';
import { useEffect } from 'react';
import { useContext } from 'react';
import { GlobalStateContext } from '../../GlobalContext/GlobalContextProvider';

import { AddNewCredential } from './Users/AddNewCredential/AddNewCredential';
import { UserList } from './Users/UsersList/UserList';
import { AddUserRole } from './UserRole/AddUserRole/AddUserRole';
import CompanyComponent from '../../components/Generic/CompanyLogo/CompanyComponents';

export interface ICameraManagementProps {
}



export const EdtechConfigsManagement = (props: ICameraManagementProps) => {
  const { globalState: { permissions } } = useContext(GlobalStateContext);

  const sidePanelRoutes = [

    {
      id: '#5',
      title: 'Users List',
      component: <UserList/>,
      accessPermission: 'CONFIGS_ADD'
    },
   
    {
      id: '#6',
      title: '+ Add New Control',
      component: <AddNewCredential  />,
      accessPermission: 'CONFIGS_ADD'
    }, 
    // {
    //   id: '#7',
    //   title: '+ Add New User Role',
    //   component: <AddUserRole  />,
    //   accessPermission: 'CONFIGS_ADD'
    // }

  ];
  const [pageVariables, setPageVariables] = React.useState({
    selectedRoute: sidePanelRoutes[0]
  })

  useEffect(() => {
    document.title = 'Cameras'
  }, []);

  return (
    <div className={'camera-management-container'}>
      <div className={'visitor-side-panel-container'}>
        <div className={'visitor-side-panel-routes-container'}>
          {
            sidePanelRoutes.map((route, index) => {
              return ([...permissions].includes(route.accessPermission) || true) && (
                <div
                  className={`visitor-side-panel-route-box 
                      ${pageVariables.selectedRoute.id === route.id ?
                      'selected-visitor-side-panel-route-box' : ''}`}
                  onClick={() => setPageVariables({ ...pageVariables, selectedRoute: route })}
                >
                  <div
                    className={pageVariables.selectedRoute.id === route.id ? 'selected-side-route' : 'not-selected-side-route'}
                  >
                  </div>
                  <div className={pageVariables.selectedRoute.id === route.id ? 'selected-visitor-side-panel-route' : 'visitor-side-panel-route'}>
                    {route.title}
                  </div>

                </div>)
            })
          }

        </div>
        <div className={'critical-ai-log-bottom'}>
           <CompanyComponent/>
        </div>
      </div>
      <div className={'visitor-content-container'}>
        {pageVariables.selectedRoute.component}
      </div>
    </div>);
}
