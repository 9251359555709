import * as React from 'react';
import './side-bar.scss';
import { LogOut } from 'lucide-react';
import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import { GlobalStateContext } from '../../GlobalContext/GlobalContextProvider';
import { useEffect } from 'react';
import { useState } from 'react';
import { sideNavBarRoutes } from '../../components/Routes/SideBarRoutes';

export interface ISideBarProps {
  sideNavBarRoutes: any;
  profileClicked: () => void;
}


export const EdtechSideBar = (props: ISideBarProps) => {
  const [selectedRoute, setSelectedRoute] = React.useState<any>("/");
  const {globalState:{permissions, company}} = useContext(GlobalStateContext);
  const navigate = useNavigate();
  const [logo, setLogo] = useState('');

  React.useEffect(() => {
    const currentPath = window.location.pathname;
    const initialSelectedRoute = sideNavBarRoutes.findIndex(
      (route) => route.routePath === currentPath
    );

    if (initialSelectedRoute !== -1) {
      setSelectedRoute(initialSelectedRoute);
    }
  }, []);

  const handleSideNavRoute = (path, index) => {
    navigate(path);
    setSelectedRoute(index);
  };

  useEffect(()=>{
    if(company && company.logo_url) {
      setLogo(company.logo_url);
    }
  },[company]);

  return (
    <div className={"taashpatti_sidenav__container"}>
    

      <div className={"sidenav__routes"}>
        {props.sideNavBarRoutes.map((navRoute, index) => {
        return [...permissions].includes(navRoute.accessName|| true)  && (
          <div
            key={index}
            className={`${"sidenav__routes-link"} ${
              selectedRoute === index ? "selected-route" : ""
            }`}
            onClick={() => handleSideNavRoute(navRoute.routePath, index)}
          >
            <div
              className={`${"sidenav__route-border"} ${
                selectedRoute === index ? "selected-border" : ""
              }`}
            ></div>
            <img
              className={"sidenav__route-image"}
              src={navRoute.routeImage}
              alt=""
            />
            <p className={"sidenav__route-name"}>{navRoute.routeName}</p>
          </div>)
        })}
      </div>

      
      <div className={"sidenav__route-logo"}>
          <div className={"logout"}
              onClick={() => props.profileClicked()}
            >          
          <div className={"sidenav__route-image"}>
                <LogOut color="white" width="30px"/>
                <p className={"sidenav__route-name"}>Logout</p>
          </div>
          </div>

          <img
            className={"sidenav__route-logo-image"}
            src='images/side_bar/bytecoder.svg'
            alt=""
            />
            
      </div>
        

    </div>
  );
};
