import * as React from 'react';
import { useCallback, useState, useRef } from 'react';
import { Button } from '../Button/Button';
import { CameraCapture } from '../CameraCapture/CameraCapture';
import { uploadFile } from '../../../API/FileUpload';
import { toast } from 'sonner';
import './file-upload.scss';

// Constants
const MAX_FILE_SIZE_DEFAULT = 5; // 5MB default
const BYTES_IN_MB = 1000000;

export enum UploadType {
  IMAGE = '.jpeg, .png, .jpg, .gif, .bmp, .tiff, .tif',
  FILE = '.pdf, .txt, .csv, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .odt, .ods, .odp',
  FILE_AND_IMAGE = '.pdf, .txt, .csv, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .odt, .ods, .odp, .jpeg, .png, .jpg, .gif, .bmp, .tiff, .tif'
}

interface FileUploadProps {
  label: string;
  width: string;
  onUpload?: (fileUrl: string) => void;
  isRequired?: boolean;
  url?: string;
  isValid?: (valid: boolean, field: string) => void;
  showWarning?: boolean;
  accept: UploadType;
  maxSizeInMB?: number;
  allowCamera?: boolean;
}

interface FileUploadState {
  openCamera: boolean;
  largeFileSelected: boolean;
  dragActive: boolean;
  showCameraPreview: boolean;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  label,
  width,
  onUpload,
  isRequired = false,
  url,
  isValid,
  showWarning = true,
  accept,
  maxSizeInMB = MAX_FILE_SIZE_DEFAULT,
  allowCamera = true
}) => {
  // State
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [state, setState] = useState<FileUploadState>({
    openCamera: false,
    largeFileSelected: false,
    dragActive: false,
    showCameraPreview: false
  });

  // Refs
  const inputRef = useRef<HTMLInputElement>(null);
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const webcamRef = useRef<any>(null);

  // Handlers
  const handleDrag = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setState(prev => ({ ...prev, dragActive: true }));
    } else if (e.type === "dragleave") {
      setState(prev => ({ ...prev, dragActive: false }));
    }
  }, []);

  const validateFileSize = useCallback((file: File): boolean => {
    const isValidSize = file.size <= maxSizeInMB * BYTES_IN_MB;
    setState(prev => ({ ...prev, largeFileSelected: !isValidSize }));
    return isValidSize;
  }, [maxSizeInMB]);

  const validateFileType = useCallback((file: File): boolean => {
    const acceptedTypes = accept.split(',').map(type => type.trim());
    const fileExtension = '.' + file.name.split('.').pop()?.toLowerCase();
    return acceptedTypes.includes(fileExtension);
  }, [accept]);

  const handleFile = useCallback(async (file: File) => {
    if (!file) return;

    if (!validateFileType(file)) {
      toast.error(`Invalid file type. Accepted types: ${accept}`);
      return;
    }

    if (!validateFileSize(file)) {
      toast.error(`File size must be less than ${maxSizeInMB}MB`);
      return;
    }

    setSelectedFile(file);
  }, [accept, maxSizeInMB, validateFileSize, validateFileType]);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setState(prev => ({ ...prev, dragActive: false }));

    const file = e.dataTransfer.files?.[0];
    if (file) {
      handleFile(file);
    }
  }, [handleFile]);

  const handleFileSelect = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      handleFile(file);
    }
  }, [handleFile]);

  const handleUpload = useCallback(async () => {
    if (!selectedFile) return;

    try {
      setUploading(true);
      const formData = new FormData();
      formData.append('file', selectedFile);

      const response = await uploadFile(formData);
      if (response?.url) {
        onUpload?.(response.url);
        toast.success('File uploaded successfully!');
        setSelectedFile(null);
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      toast.error('Failed to upload file. Please try again.');
    } finally {
      setUploading(false);
    }
  }, [selectedFile, onUpload]);

  const handleCameraCapture = useCallback(async (file: File) => {
    try {
      if (!file) return;

      setUploading(true);
      setState(prev => ({ ...prev, openCamera: false }));

      const formData = new FormData();
      formData.append('file', file, file.name);

      const response = await uploadFile(formData);
      if (response?.url) {
        onUpload?.(response.url);
        toast.success('Image captured and uploaded successfully!');
        setState(prev => ({ ...prev, showCameraPreview: false }));
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      toast.error('Failed to upload camera image. Please try again.');
    } finally {
      setUploading(false);
    }
  }, [onUpload]);

  // Effects
  React.useEffect(() => {
    if (isValid) {
      isValid(!isRequired || !!url, label);
    }
  }, [isRequired, url, label, isValid]);

  // Render helpers
  const renderDragDropText = () => (
    <div className="upload-text">
      {state.dragActive 
        ? 'Drop the file here'
        : `Drag and drop or click to select a file ${isRequired ? '*' : ''}`
      }
    </div>
  );

  const renderFileInfo = () => {
    if (!selectedFile) return null;
    return (
      <div className="file-info">
        <span className="file-name">{selectedFile.name}</span>
        <span className="file-size">({(selectedFile.size / BYTES_IN_MB).toFixed(2)}MB)</span>
      </div>
    );
  };

  const renderCameraButton = () => {
    if (!allowCamera || (accept !== UploadType.IMAGE && accept !== UploadType.FILE_AND_IMAGE)) return null;

    return (
      <div className="capture-from-camera">
        <div
          className="camera-btn"
          onClick={() => setState(prev => ({ ...prev, openCamera: true }))}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              setState(prev => ({ ...prev, openCamera: true }));
            }
          }}
        >
          <img src="/images/camera.svg" alt="Camera" />
          <span>Capture from Camera</span>
        </div>
      </div>
    );
  };

  return (
    <div className="file-uploader-container" style={{ width }}>
      <label className="file-uploader-label">
        {label}
        {isRequired && <span className="required">*</span>}
      </label>

      <div
        ref={dropZoneRef}
        className={`file-uploader-section-parent-div ${state.dragActive ? 'drag-active' : ''}`}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <div className="file-uploader-section">
          <input
            ref={inputRef}
            type="file"
            accept={accept}
            onChange={handleFileSelect}
            aria-label={`Upload ${label}`}
          />
          {renderDragDropText()}
        </div>

        {renderFileInfo()}

        {state.largeFileSelected && showWarning && (
          <div className="error-message">
            File size exceeds {maxSizeInMB}MB limit
          </div>
        )}

        {renderCameraButton()}

        {selectedFile && !state.largeFileSelected && (
          <div className="upload-button">
            <Button
              text={uploading ? 'Uploading...' : 'Upload'}
              bgcolor="#28C670"
              textcolor="white"
              width="100%"
              onClick={handleUpload}
              disabled={uploading}
              class="medium-btn-container"
            />
          </div>
        )}
      </div>

      {state.openCamera && (
        <CameraCapture
          upload={handleCameraCapture}
          closeCamera={() => setState(prev => ({ ...prev, openCamera: false }))}
        />
      )}
    </div>
  );
};
