import * as React from 'react';
import { useContext, useRef, useState, useCallback, useEffect } from 'react';
import { GlobalStateContext } from '../../../GlobalContext/GlobalContextProvider';
import { Button } from '../Button/Button';
import './filters.scss';

type FilterKeys = 'start_date' | 'end_date' | 'unit' | 'category' | 'activity' | 'gate_number';

export interface FilterValue {
  start_date: string;
  end_date: string;
  unit: string;
  category: string;
  activity: string;
  gate_number: string;
}

export interface FilterOption {
  label: string;
  value: string;
}

export interface IFiltersProps {
  apply: (filters: FilterValue | null) => void;
  unitFilter?: boolean;
  categoryFilter?: boolean;
  activityFilter?: boolean;
  gateFilter?: boolean;
  dateFilter?: boolean;
  className?: string;
  initialFilters?: Partial<FilterValue>;
  options?: {
    units?: FilterOption[];
    categories?: FilterOption[];
    activities?: FilterOption[];
    gates?: FilterOption[];
  };
}

export const Filters: React.FC<IFiltersProps> = ({
  apply,
  unitFilter = false,
  categoryFilter = false,
  activityFilter = false,
  gateFilter = false,
  dateFilter = false,
  className = '',
  initialFilters = {},
  options = {}
}) => {
  const { globalState: { user, gates: gatesList } } = useContext(GlobalStateContext);
  const [META_DATA] = useState(user?.application_configs || {});
  
  const today = new Date();
  const formattedToday = today.toISOString().split('T')[0];
  
  const defaultFilters: FilterValue = {
    start_date: '',
    end_date: '',
    unit: '',
    category: '',
    activity: '',
    gate_number: '',
    ...initialFilters
  };

  const [filters, setFilters] = useState<FilterValue>(defaultFilters);
  const [isOpen, setIsOpen] = useState(false);
  const [activeFiltersCount, setActiveFiltersCount] = useState(0);
  
  const filterRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLButtonElement>(null);

  const handleOutsideClick = useCallback((event: MouseEvent) => {
    if (
      filterRef.current &&
      !filterRef.current.contains(event.target as Node) &&
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  }, []);

  const countActiveFilters = useCallback(() => {
    return Object.values(filters).filter(value => value !== '').length;
  }, [filters]);

  const handleFilterChange = (key: FilterKeys, value: string) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  const handleApply = () => {
    const hasActiveFilters = countActiveFilters() > 0;
    if (hasActiveFilters) {
      apply(filters);
      setIsOpen(false);
    }
  };

  const handleClear = () => {
    setFilters(defaultFilters);
    apply(null);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setActiveFiltersCount(countActiveFilters());
  }, [filters, countActiveFilters]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const renderDateFilter = () => (
    <div className="filter-section">
      <div className="filter-label">Date Range</div>
      <div className="date-range">
        <div className="date-input">
          <label htmlFor="start-date">From</label>
          <input
            id="start-date"
            type="date"
            value={filters.start_date}
            max={filters.end_date || formattedToday}
            onChange={(e) => handleFilterChange('start_date', e.target.value)}
            aria-label="Start date"
          />
        </div>
        <div className="date-input">
          <label htmlFor="end-date">To</label>
          <input
            id="end-date"
            type="date"
            value={filters.end_date}
            min={filters.start_date}
            max={formattedToday}
            onChange={(e) => handleFilterChange('end_date', e.target.value)}
            aria-label="End date"
          />
        </div>
      </div>
    </div>
  );

  const getFilterOptions = (key: string): FilterOption[] => {
    if (options[key]) return options[key];

    switch (key) {
      case 'units':
        return (META_DATA.Unit || []).map(unit => ({ label: unit, value: unit }));
      case 'categories':
        return (META_DATA.Category || []).map(cat => ({ label: cat, value: cat }));
      case 'activities':
        return (META_DATA.ActivityType || []).map(act => ({ label: act, value: act }));
      case 'gates':
        return (gatesList || []).map(gate => ({ 
          label: `Gate ${gate.gate_number}`, 
          value: gate.gate_number 
        }));
      default:
        return [];
    }
  };

  const renderSelect = (
    key: FilterKeys,
    label: string,
    optionsKey: string
  ) => {
    const selectId = `filter-${key}`;
    return (
      <div className="filter-section">
        <label htmlFor={selectId} className="filter-label">
          {label}
        </label>
        <select
          id={selectId}
          className="filter-select"
          value={filters[key]}
          onChange={(e) => handleFilterChange(key, e.target.value)}
          aria-label={label}
        >
          <option value="">All</option>
          {getFilterOptions(optionsKey).map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  };

  return (
    <div className={`filter-container ${className}`}>
      <button
        ref={filterButtonRef}
        className={`filter-button ${isOpen ? 'active' : ''} ${activeFiltersCount > 0 ? 'has-filters' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
        aria-label="Toggle filters"
        type="button"
      >
        <img src="/images/generic/Filters.svg" alt="" aria-hidden="true" />
        {activeFiltersCount > 0 && (
          <span className="filter-count" aria-label={`${activeFiltersCount} active filters`}>
            {activeFiltersCount}
          </span>
        )}
      </button>

      {isOpen && (
        <div
          ref={filterRef}
          className="filter-popover"
          role="dialog"
          aria-label="Filter options"
          onKeyDown={handleKeyDown}
        >
          {dateFilter && renderDateFilter()}
          
          {unitFilter && renderSelect('unit', 'Unit', 'units')}
          {categoryFilter && renderSelect('category', 'Category', 'categories')}
          {activityFilter && renderSelect('activity', 'Activity', 'activities')}
          {gateFilter && renderSelect('gate_number', 'Gate', 'gates')}

          <div className="filter-actions">
            <Button
              variant="secondary"
              size="small"
              onClick={handleClear}
              disabled={activeFiltersCount === 0}
            >
              Clear
            </Button>
            <Button
              variant="primary"
              size="small"
              onClick={handleApply}
              disabled={activeFiltersCount === 0}
            >
              Apply
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
