import { Link, useNavigate } from 'react-router-dom';
import { Loader } from './Loader/Loader';
import './generic-table.scss';
import Pagination from './Pagination/Pagination';
import { useState } from 'react';

interface ITableProps {
    columnDetails: any,
    dataRows: any,
    selectedRow?: (row) => void,

    totalItems: number,
    initialLimit: number,
    onPageChange: (newPage) => void,
    onLimitChange: (newPage) => void,

    loading?: boolean,
    id?: string,
    pagination?: boolean
    currentPage?: number

}

export const GenericTable = (props: ITableProps) => {
    const navigate = useNavigate();

    const getDataFormatted = (columDetails, row) => {
        let style = {};

        if (columDetails?.image_key) {
            return (
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                    {row[columDetails.image_key] &&
                        <img src={row[columDetails.image_key]} style={{ height: '2rem', width: '2rem', objectFit: 'cover' }} alt={`${columDetails.header} image`} />}
                    <div style={{ fontWeight: 'bold' }}>
                        {row[columDetails.key]}
                    </div>
                </div>
            )
        }
        else if (columDetails?.route) {
            style = { color: 'green', textDecoration: 'underline' }
            return <div style={{ color: 'green', textDecoration: 'underline' }}> {row[columDetails.key]} </div>
        }
        else if (columDetails?.inOut) {
            return (<div style={{
                backgroundColor: row[columDetails.key]?.toLowerCase() === 'in' ? '#4D4D33' : '#7B0323',
                color: 'white',
                width: 'fit-content',
                padding: row[columDetails.key]?.toLowerCase() === 'in' ? '0.1rem 1rem' : '0.1rem 0.5rem',
                borderRadius: '1rem',
                fontWeight: 'bold'

            }}>
                <div>{row[columDetails.key]}</div>
            </div>)
        }
        else if (columDetails?.bold) {
            return <div style={{ fontWeight: 'bold' }}>{row[columDetails.key]}</div>
        }
        else if (columDetails?.status) {
            return (<div style={{
                backgroundColor: row[columDetails.key]?.toLowerCase() === 'active' ? '#ECFDF3' : '#F2F4F7',
                color: row[columDetails.key]?.toLowerCase() === 'active' ? '#037847' : '#364254',
                width: 'fit-content',
                padding: row[columDetails.key]?.toLowerCase() === 'in' ? '0.1rem 1rem' : '0.1rem 0.5rem',
                borderRadius: '1rem',
                fontSize: '0.8rem'

            }}>
                <div>{row[columDetails.key]}</div>
            </div>)
        }
        else if (columDetails?.btnRoute) {
            return (<Link to={row[columDetails.key]} target={'_blank'} style={{ textDecoration: 'none' }}>
                <div style={{
                    backgroundColor: '#ECFDF3',
                    color: '#037847',
                    width: '100%',
                    padding: '0.1rem 0.5rem',
                    borderRadius: '1rem',
                    border: '0.05rem solid #037847',
                    fontSize: '0.8rem'
                }}
                >
                    <div>{columDetails?.btnRoute}</div>
                </div> </Link>)
        }
        else return <div> {row[columDetails.key]} </div>

    }



    return (
        <div className={"generic-table-container"}>
            <table id={props.id}>
                <thead>
                    {props.columnDetails.map(column => {
                        return (
                            <td>
                                {column.title}
                            </td>
                        )
                    })}
                </thead>
                {props.loading ?
                    <tr>
                        <td colSpan={props.columnDetails.length} style={{ backgroundColor: 'white', textAlign: 'center' }}>
                            <Loader />
                        </td>
                    </tr>
                    :
                    (props.dataRows && props.dataRows.length) ? props.dataRows.map(row => {
                        return (
                            <tr
                                onClick={() => props?.selectedRow && props?.selectedRow(row)}
                            >
                                {props.columnDetails?.map((column, index) => {
                                    return (
                                        <td onClick={() => {
                                            if (props.columnDetails[index]?.route) {
                                                navigate(props.columnDetails[index]?.route + '/' + row[column.route_key]);
                                            }
                                        }}
                                            width={'10%'}
                                        >
                                            {getDataFormatted(props.columnDetails[index], row)}
                                        </td>);
                                })}
                            </tr>
                        )
                    }) :
                        <tr>
                            <td colSpan={props.columnDetails.length}>
                                <div style={{
                                    textAlign: 'center',
                                    width: '100%',
                                }}>
                                    No record found
                                </div>
                            </td>
                        </tr>
                }
            </table>
            {/* && props.pagination != null */}
            {(!props.loading && props.pagination != null) &&
                < Pagination
                    currentPage={props.currentPage}
                    totalItems={props.totalItems}
                    initialLimit={props.initialLimit}
                    onPageChange={props.onPageChange}
                    onLimitChange={props.onLimitChange}
                />
            }
        </div>);
}