import React, { useState } from 'react';
import './feature-input.scss';

export interface Feature {
  name: string;
  value: string;
}

export interface FeatureInputProps {
  features?: Feature[];
  onChange: (features: Feature[]) => void;
  label?: string;
  placeholder?: string;
  width?: string;
  value?: string;
  isRequired?: boolean;
}

const FeatureInput: React.FC<FeatureInputProps> = ({
  features = [],
  onChange,
  label,
  placeholder,
  width = '100%',
  isRequired = false,
}) => {
  const [featureList, setFeatureList] = useState<Feature[]>(features);

  const handleAddFeature = () => {
    const newFeatures = [...featureList, { name: '', value: '' }];
    setFeatureList(newFeatures);
    onChange(newFeatures);
  };

  const handleRemoveFeature = (index: number) => {
    const newFeatures = featureList.filter((_, i) => i !== index);
    setFeatureList(newFeatures);
    onChange(newFeatures);
  };

  const handleFeatureChange = (index: number, field: keyof Feature, value: string) => {
    const newFeatures = [...featureList];
    newFeatures[index][field] = value;
    setFeatureList(newFeatures);
    onChange(newFeatures);
  };

  return (
    <div className="feature-input" style={{ width }}>
      {label && <label className="feature-input-label">{label}</label>}
      {featureList.map((feature, index) => (
        <div key={index} className="feature-row">
          <input
            type="text"
            placeholder={`${placeholder || 'Feature'} Name`}
            value={feature.name}
            onChange={(e) => handleFeatureChange(index, 'name', e.target.value)}
            required={isRequired}
          />
          <input
            type="text"
            placeholder={`${placeholder || 'Feature'} Value`}
            value={feature.value}
            onChange={(e) => handleFeatureChange(index, 'value', e.target.value)}
            required={isRequired}
          />
          <button type="button" onClick={() => handleRemoveFeature(index)}>
            Remove
          </button>
        </div>
      ))}
      <button type="button" onClick={handleAddFeature}>
        Add Feature
      </button>
    </div>
  );
};

export default FeatureInput;
