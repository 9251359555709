import * as React from 'react';
import { useState, useCallback, useRef } from 'react';
import './search-bar.scss';

export interface ISearchBarProps {
  placeholder: string;
  width?: string;
  onSearch: (query: string) => void;
  debounceTime?: number;
  minLength?: number;
  maxLength?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onClear?: () => void;
  className?: string;
}

export const SearchBar: React.FC<ISearchBarProps> = ({
  placeholder,
  width = '100%',
  onSearch,
  debounceTime = 500,
  minLength = 0,
  maxLength,
  autoFocus = false,
  disabled = false,
  loading = false,
  onClear,
  className = ''
}) => {
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const debounce = useCallback(
    (func: (query: string) => void, wait: number) => {
      return (query: string) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          func(query);
        }, wait);
      };
    },
    []
  );

  const debouncedSearch = useCallback(
    debounce((query: string) => {
      if (query.length >= minLength) {
        onSearch(query);
      }
    }, debounceTime),
    [debounceTime, minLength, onSearch]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    debouncedSearch(newValue);
  };

  const handleClear = () => {
    setValue('');
    if (onClear) {
      onClear();
    } else {
      onSearch('');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      handleClear();
    } else if (event.key === 'Enter') {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      onSearch(value);
    }
  };

  return (
    <div 
      className={`search-bar-container ${isFocused ? 'focused' : ''} ${
        disabled ? 'disabled' : ''
      } ${className}`}
      style={{ width }}
    >
      <div className="search-bar-icon">
        <img src="/images/generic/mag-glass.svg" alt="Search" />
      </div>

      <input
        type="text"
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        autoFocus={autoFocus}
        aria-label={placeholder}
      />

      {loading && (
        <div className="search-bar-loader">
          <div className="loader-spinner" />
        </div>
      )}

      {value && !loading && (
        <button
          className="search-bar-clear"
          onClick={handleClear}
          aria-label="Clear search"
          type="button"
        >
          <span aria-hidden="true">×</span>
        </button>
      )}
    </div>
  );
};
