import * as React from 'react';
import './generic-button.scss';

export type ButtonSize = 'small' | 'medium' | 'large';
export type ButtonVariant = 'primary' | 'secondary' | 'danger' | 'success' | 'text';

// Legacy props interface for backward compatibility
interface ILegacyButtonProps {
  bgcolor: string;
  textcolor: string;
  width: string;
  padding?: string;
  margin?: string;
  text: string;
  class?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  id?: string;
  disabled?: boolean;
  fontSize?: string; // Add fontSize prop
}

// Modern props interface
interface IModernButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  fullWidth?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  className?: string;
  children?: React.ReactNode;
  fontSize?: string; // Add fontSize prop
}

export type IButtonProps = ILegacyButtonProps | IModernButtonProps;

const isLegacyProps = (props: IButtonProps): props is ILegacyButtonProps => {
  return 'bgcolor' in props || 'textcolor' in props || 'text' in props;
};

export const Button: React.FC<IButtonProps> = (props) => {
  if (isLegacyProps(props)) {
    // Handle legacy props
    const {
      bgcolor,
      textcolor,
      width,
      padding,
      margin,
      text,
      class: className,
      onClick,
      id,
      disabled,
      fontSize, // Add fontSize prop
    } = props;

    const style: React.CSSProperties = {
      backgroundColor: bgcolor,
      color: textcolor,
      width,
      padding,
      margin,
      fontSize: fontSize || '1rem', // Use fontSize prop with default
    };

    return (
      <button
        className={`btn-legacy ${className || ''}`}
        onClick={onClick}
        id={id}
        disabled={disabled}
        style={style}
        type="button"
      >
        {text}
      </button>
    );
  }

  // Handle modern props
  const {
    variant = 'primary',
    size = 'medium',
    fullWidth = false,
    loading = false,
    icon,
    iconPosition = 'left',
    className = '',
    children,
    disabled,
    type = 'button',
    fontSize, // Add fontSize prop
    ...restProps
  } = props;

  const baseClass = 'btn';
  const classes = [
    baseClass,
    `${baseClass}--${variant}`,
    `${baseClass}--${size}`,
    fullWidth ? `${baseClass}--full-width` : '',
    loading ? `${baseClass}--loading` : '',
    disabled ? `${baseClass}--disabled` : '',
    className
  ].filter(Boolean).join(' ');

  const renderIcon = () => {
    if (!icon) return null;
    return <span className={`${baseClass}__icon ${baseClass}__icon--${iconPosition}`}>{icon}</span>;
  };

  const renderContent = () => (
    <>
      {loading && (
        <span className={`${baseClass}__loader`}>
          <span className="loader-dot" />
          <span className="loader-dot" />
          <span className="loader-dot" />
        </span>
      )}
      {iconPosition === 'left' && renderIcon()}
      {children && <span className={`${baseClass}__text`}>{children}</span>}
      {iconPosition === 'right' && renderIcon()}
    </>
  );

  return (
    <button
      className={classes}
      disabled={disabled || loading}
      type={type}
      style={{ fontSize: fontSize || '1rem' }} // Use fontSize prop with default
      {...restProps}
    >
      {renderContent()}
    </button>
  );
};