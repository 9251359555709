import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import './text-area.scss';

export interface ITextAreaProps {
  placeholder: string;
  label: string;
  width: string;
  height: string;
  value?: string;
  onChange: (value: string) => void;
  isRequired?: boolean;
  isValid?: (valid: boolean, field: string) => void;
  showWarning?: boolean;
  maxLength?: number;
  disabled?: boolean;
  error?: string;
  rows?: number;
  minRows?: number;
  maxRows?: number;
  autoResize?: boolean;
}

export const TextArea: React.FC<ITextAreaProps> = ({
  placeholder,
  label,
  width,
  height,
  value = '',
  onChange,
  isRequired = false,
  isValid,
  showWarning = false,
  maxLength,
  disabled = false,
  error,
  rows = 3,
  minRows = 3,
  maxRows = 10,
  autoResize = false
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [showError, setShowError] = useState(false);
  const [textAreaHeight, setTextAreaHeight] = useState(height);
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

  const validate = useCallback(() => {
    if (isRequired && (!inputValue && !value)) {
      return false;
    }
    return true;
  }, [inputValue, value, isRequired]);

  useEffect(() => {
    if (isValid) {
      const isValidInput = validate();
      isValid(isValidInput, label);
    }
  }, [inputValue, value, isValid, label, validate]);

  const adjustHeight = useCallback(() => {
    const textArea = textAreaRef.current;
    if (!textArea || !autoResize) return;

    textArea.style.height = 'auto';
    const lineHeight = parseInt(getComputedStyle(textArea).lineHeight);
    const minHeight = minRows * lineHeight;
    const maxHeight = maxRows * lineHeight;
    
    const newHeight = Math.min(Math.max(textArea.scrollHeight, minHeight), maxHeight);
    setTextAreaHeight(`${newHeight}px`);
  }, [minRows, maxRows, autoResize]);

  useEffect(() => {
    adjustHeight();
  }, [value, inputValue, adjustHeight]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    if (maxLength && newValue.length > maxLength) return;

    setInputValue(newValue);
    onChange(newValue);
    setShowError(showWarning && !validate());
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setShowError(showWarning && !validate());
  };

  const displayValue = value || inputValue;

  return (
    <div style={{ width }} className="text-area-container">
      <label className="text-area-label">
        {label}
        {isRequired && <span className="required">*</span>}
      </label>

      <div
        className={`text-area-form ${isFocused ? 'focused' : ''} ${
          showError ? 'error' : ''
        } ${disabled ? 'disabled' : ''}`}
      >
        <textarea
          ref={textAreaRef}
          style={{ height: textAreaHeight }}
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={displayValue}
          maxLength={maxLength}
          disabled={disabled}
          rows={rows}
          aria-label={label}
          aria-required={isRequired}
          aria-invalid={showError}
        />

        {maxLength && (
          <div className="char-count">
            {displayValue.length}/{maxLength}
          </div>
        )}
      </div>

      {(error || showError) && (
        <div className="error-message">
          {error || `Please enter a valid ${label.toLowerCase()}`}
        </div>
      )}
    </div>
  );
};
