import "./App.scss";
import {
  BrowserRouter as Router
} from "react-router-dom";
import { ecommerceSideNavBarRoutes, edtechSideNavBarRoutes, iotSideNavBarRoutes, kaleidoViewSideNavBarRoutes, serviceProvidorSideNavBarRoutes, sideNavBarRoutes, TaashpattiSideNavBarRoutes } from "./components/Routes/SideBarRoutes";

import { useState } from "react";
import { Toaster } from 'sonner';
import EDTechApp from "./Edtech/EDTechApp";





export const apps = [
  {
    name: 'IOT Admin',
    navbar: iotSideNavBarRoutes
  },
  {
    name: 'GPI Admin',
    navbar: sideNavBarRoutes
  },
  {
    name: 'E-coomerce Admin',
    navbar: ecommerceSideNavBarRoutes
  },
  {
    name: 'EdTech Admin',
    navbar: edtechSideNavBarRoutes
  },
  {
    name: 'Service Provide Admin',
    navbar: serviceProvidorSideNavBarRoutes
  },
  {
    name: 'KaleidoView Admin',
    navbar: kaleidoViewSideNavBarRoutes
  },
   {
    name: 'Taashpatti Admin',
    navbar: TaashpattiSideNavBarRoutes
  }
]

function App() {


  const [sidenav, setSideNav] = useState(apps[3])


  return (
    <div className="App">
      <Toaster position="top-right" duration={3000} />
          <Router>


            <div style={{ paddingTop: '0rem', width: '100%' }}>


              <div style={{  width: '100%', height: '100vh' }}>

                 

                     {/* Kaleido view  */}
                     { sidenav.name == "EdTech Admin" && 
                     <EDTechApp sidenav = {sidenav}/>
                    }
            
              </div>
            </div>
          </Router>
         
    </div>
  );
}

export default App;
