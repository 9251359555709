import * as React from 'react';
import './drop-down.scss';

export enum DropDownVariant {
  singleValue = 'singleValue',
  multiValue = 'multiValue'
}
export interface IDropdownProps {
    placeholder: string;
    label: string;
    width: string;
    options: string[];
    onSelect?: (selection: string) => void;
    selection?: any;
    isRequired?: boolean;
    isValid?: (valid: boolean, field: string) => void;
    showWarning?: boolean;
    variant?: DropDownVariant

}

const Dropdown = (props: IDropdownProps) =>{
  const [inputValue, setInputValue] = React.useState<any>();
  const [multiValues, setMultiValues] = React.useState<any>([]);

  const validate = () => {
    if(props.isRequired && !props.selection) {
      return false;
    }
    return true;
  }

  React.useEffect(()=>{
    
    if(props.isValid) {
      if(validate()) props.isValid(true, props.label);
      else props.isValid(false, props.label);
    }

  },[inputValue, props.selection]);

  React.useEffect(()=>{
    
    if(props.isValid) {
      if(validate()) props.isValid(true, props.label);
      else props.isValid(false, props.label);
    }

  },[multiValues, props.selection]);


  return (
    <div className={'drop-down-container'}
      style={{width:props.width}}
    >
      <div className={'text-input-label'}>
          {props.label} {props.isRequired && <span style={{color:'red'}}>*</span>}
      </div>
      <div className={'text-input-form'} style={{border: `${props.showWarning && !validate() ? '0.01rem solid red' : ''}`}}>
          <select
            style={{width:'100%'}}
            onChange={(event) => {

              if(props.variant && props.variant === DropDownVariant.multiValue) {
                if(multiValues.includes(event.target.value)) return;
                let selectedValues = [...multiValues, event.target.value];
                setMultiValues(selectedValues);
                props.onSelect(selectedValues.toString());

                console.log(selectedValues)
                console.log(props.selection)
              }
              else {
                props.onSelect(event.target.value);
                setInputValue(event.target.value);
              }

            }}
            value={props.selection}
          >
              <option value={""} selected>
                    {props.placeholder}
              </option>
              {
                  props.options.map(
                      optionValue => {
                        return <option value={optionValue}>
                              {optionValue}
                        </option>
                      }
                )
              }
          </select>
          <div className={'multiValues-chip-container'}>
            {
              (props.variant === DropDownVariant.multiValue) && props.selection && props.selection.split(",").map((number) => {
                return <div 
                        className={'multiValues-chip'} 
                        >
                          {number}
                          <img  
                            style={
                              { height:'1.1rem', 
                                marginLeft:'0.2rem',
                                cursor:'pointer'
                              }}
                            src={'/images/generic/cross.svg'}
                            onClick={()=>{ 
                              let filtered = props.selection.split(",").filter(num => num !== number);
                              props.onSelect(filtered.toString());
                              setMultiValues(filtered);
                            }}
                          />
                        </div>
              })
            }
      </div>
      </div>
    </div>
  );
}


export default Dropdown;