interface UploadResponse {
  url: string;
}

export const uploadFile = async (formData: FormData): Promise<UploadResponse> => {
  try {
    const response = await fetch('/api/upload', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('File upload failed');
    }

    const data = await response.json();
    return { url: data.fileUrl };
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};
