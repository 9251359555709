import * as React from 'react';
import './loader.scss';

interface ILoaderProps {
  loadingText?: string;
  height?: string;
  size?: 'small' | 'default';
  variant?: 'inline' | 'default';
  className?: string;
}

export const Loader: React.FC<ILoaderProps> = ({
  loadingText = 'Loading',
  height = '30vh',
  size = 'default',
  variant = 'default',
  className = ''
}) => {
  const containerClasses = [
    'loader-container',
    size === 'small' && 'small',
    variant === 'inline' && 'inline',
    className
  ].filter(Boolean).join(' ');

  return (
    <div className={containerClasses} style={{ height }}>
      <div className="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {loadingText && (
        <div className="loading-message">
          {loadingText}...
        </div>
      )}
    </div>
  );
};

export default Loader;
