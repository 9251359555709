import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import './text-input.scss';

export interface ITextInputProps {
  placeholder: string;
  label: string;
  width: string;
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  isRequired?: boolean;
  maxLength?: number;
  isValid?: (valid: boolean, field: string) => void;
  showWarning?: boolean;
  isNumber?: boolean;
  regEx?: RegExp;
  type?: 'text' | 'password' | 'email' | 'tel';
  autoComplete?: string;
  error?: string;
}

const TextInput: React.FC<ITextInputProps> = ({
  placeholder,
  label,
  width,
  value = '',
  onChange,
  disabled = false,
  isRequired = false,
  maxLength,
  isValid,
  showWarning = false,
  isNumber = false,
  regEx,
  type = 'text',
  autoComplete,
  error
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState(false);
  const [showError, setShowError] = useState(false);

  const validate = useCallback(() => {
    if (isRequired && (!inputValue && !value)) {
      return false;
    }
    if (regEx && inputValue) {
      if (!new RegExp(regEx).test(inputValue)) {
        return false;
      }
    }
    return true;
  }, [inputValue, value, isRequired, regEx]);

  useEffect(() => {
    if (isValid) {
      const isValidInput = validate();
      isValid(isValidInput, label);
    }
  }, [inputValue, value, isValid, label, validate]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;

    if (isNumber) {
      newValue = newValue.replace(/[^0-9]/g, '');
    }

    if (maxLength && newValue.length > maxLength) {
      return;
    }

    setInputValue(newValue);
    onChange?.(newValue);
    setShowError(showWarning && !validate());
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setShowError(showWarning && !validate());
  };

  const displayValue = value || inputValue;

  return (
    <div style={{ width }} className="text-input-container">
      <label className="text-input-label">
        {label}
        {isRequired && <span className="required">*</span>}
      </label>
      
      <div 
        className={`text-input-form ${isFocused ? 'focused' : ''} ${
          showError ? 'error' : ''
        } ${disabled ? 'disabled' : ''}`}
      >
        <input
          type={type}
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={displayValue}
          disabled={disabled}
          maxLength={maxLength}
          autoComplete={autoComplete}
          aria-label={label}
          aria-required={isRequired}
          aria-invalid={showError}
        />
        
        {maxLength && (
          <div className="char-count">
            {displayValue.length}/{maxLength}
          </div>
        )}
      </div>

      {(error || showError) && (
        <div className="error-message">
          {error || `Please enter a valid ${label.toLowerCase()}`}
        </div>
      )}
    </div>
  );
};

export default TextInput;