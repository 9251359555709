import React, { useEffect, useRef, useState } from "react";
import "./ModifyPlan.scss";
// import { CgClose } from "react-icons/cg";
// import { FaTrash, FaPlus } from "react-icons/fa"; // Import FaTrash for delete button
import { getExamPlanDetails, updateExamPlanDetails } from "../../../../../../APIServices/QP/ExamPlans";
import { Switch } from 'antd';
import { getExamSubjectById, updateSubject } from "../../../../../../APIServices/QP/Subjects";
import { toast } from "sonner";
import { FileUpload, UploadType } from "../../../../../../../components/Generic/FileUpload/FileUpload";
import TextInput from "../../../../../../../components/Generic/TextInput/TextInput";
import { Button } from "../../../../../../../components/Generic/Button/Button";

function ModifySubject(props: {
  examId: any, subjectId: any,
  setShowModifyPopup: any,
}) {

  const form = {
    exam_id: props.examId,
    title: "",
    image_url: "",
    code: "",
  }
  const [formData, setFormData] = useState<any>(form);

  const [invalidFields, setInvalidFields] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);

  const validateFields = React.useCallback(async (valid: boolean, field: string) => {
    if (valid) {
      if (invalidFields.includes(field))
        setInvalidFields(Fields => {
          let filtered = Fields.filter(value => value !== field);
          return filtered;
        });
    }
    else {
      if (!invalidFields.includes(field))
        setInvalidFields(Fields => {
          Fields.push(field);
          return Fields;
        });
    }
  }, []);



  const saveChanges = () => {
    console.log(invalidFields)
    if (invalidFields.length) {
      toast.warning('Please fill all the required fields!');
      setSubmitClicked(true);
      return;
  }

    updateSubject(props.examId, props.subjectId, formData).then(res=>{
      console.log(res)
      props.setShowModifyPopup()
    })
  }

  useEffect(()=>{
    getExamSubjectById(props.examId, props.subjectId).then(res=> setFormData(res))
  },[])

  return (
    <div className="add-plan-container">
      <div className="heading">
        <h3>Update Subject</h3>
        <div className="close" onClick={() => props.setShowModifyPopup()} > X </div>
      </div>
      

          <div className={'add-camera-form-row-1'}>
         
          <FileUpload
                label={'Upload Icon'}
                width={'30rem'}
                accept={UploadType.FILE}
                isRequired={false}
                onUpload={(url) => setFormData({...formData, image_url:url})}
                url={formData.image_url}
                // isValid={validateFields}
                showWarning={submitClicked}
            />

        
        
        
          </div>

    

      <div className="add-camera-form-row-2">
        <TextInput
              label={'Title'}
              placeholder={'Enter subject title'}
              width={'90%'}
              onChange={(value) => setFormData({ ...formData, title: value })}
              value={formData.title}
              isRequired={true}
              isValid={validateFields}
              showWarning={submitClicked}
            />
      </div>

   

      <div className="add-camera-form-row-2">
        <TextInput
              label={'Code'}
              placeholder={'Enter unique code'}
              width={'90%'}
              onChange={(value) => setFormData({ ...formData, code: value })}
              value={formData.code}
              isRequired={true}
              isValid={validateFields}
              showWarning={submitClicked}
              disabled
            />
      </div>

      <div className="add-camera-form-row-3">
          <Button
                class='btn-container'
                bgcolor={'#02720d'}
                text={'Update Subject'}
                textcolor={'white'}
                width={''}
                fontSize={'1rem'}
                onClick={() => {
                    saveChanges()
                }
              }
          
          ></Button>
      </div>

    
     
    </div>
  );
}

export default ModifySubject;
