import "./EdTechApp.scss";
import {
  Link,
  Route, Routes
} from "react-router-dom";
import { ROUTES, TAASHPATTI_ROUTES } from "../components/Routes/SideBarRoutes";
import { GlobalDispatchContext, GlobalStateContext } from '../GlobalContext/GlobalContextProvider';
import { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { ACTIONS } from "../GlobalContext/GlobalReducer";
import { Toaster } from 'sonner';
import { UserCompany } from '../components/UserCompany/UserCompany';
import { NotificationAcknowledgement } from "../components/NotificationAck/NotificationAcknowledgementPop";
import { BlogsManagement } from "./BlogsManagement/BlogsManagement";
import { EdtechConfigsManagement } from "./EdtechConfigManagement/EdtechConfigsManagenet";
import { QuestionsTaggingManagement } from "./QuestionTaggingManagement/QuestionsTaggingManagement";
import { EdtechDashboardManagement } from "./EdTechDashboardManagement/DashboardManagenet";
import ModifyQuestion from "./QPManagement/QPPaperManagement/QPPaperDetails/QPPaperDetailsTab/QuestionManagement/EditQuestion/ModifyQuestion";

import { CandidateDetails } from './CandidateManagement/CandidateDetails/CandidateDetails';
import { LOCAL_STORAGE_KEYS, Login } from './Login/Login';
import { CandidateManagement } from "./CandidateManagement/CandidateManagement";
import { QPManagement } from "./QPManagement/QPManagenet";
import { QPExamDetails } from "./QPManagement/ExamsManagement/QPExamDetails/QPExamDetails";
import { QPPaperDetails } from "./QPManagement/QPPaperManagement/QPPaperDetails/QPPaperDetails";
import { EditQPExam } from "./QPManagement/ExamsManagement/EditQPExam/EditQPExam";
import { EditQPPaper } from "./QPManagement/QPPaperManagement/EditQPPaper/EditQPPaper";
import QuestionViewer from "./QPManagement/QPPaperManagement/QPPaperDetails/QPPaperDetailsTab/QuestionManagement/QuestionViewer/QuestionViewer";
import { EdtechSideBar } from "./SideBar/EdtechSideBar";


function EDTechApp(props : { sidenav}) {
  const { globalState: { loggedIn, user, popNotificationAlert } } = useContext(GlobalStateContext);
  const globalDispatcher = useContext(GlobalDispatchContext);
  const [pageVariables, setPageVariables] = useState({
    showLogOutMenu: false,
    showDashboardMenu: false,
    showNotificationPop: false,
    showAppsPop: false,
  });

  const userDropdown = useRef(null);

  const [networkStatus, setNetworkStatus] = useState(false)

  const findOutsideClick = (event) => {
    if (userDropdown.current && !userDropdown.current.contains(event.target)) {
      setPageVariables({ ...pageVariables, showLogOutMenu: false });
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", findOutsideClick);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", findOutsideClick);
    };
  }, [userDropdown]);

  useEffect(() => {
    if (loggedIn && popNotificationAlert) {
      setPageVariables({ ...pageVariables, showNotificationPop: true });
    }
    else setPageVariables({ ...pageVariables, showNotificationPop: false });
  }, [popNotificationAlert]);

  const logout = async () => {
    setPageVariables({ ...pageVariables, showLogOutMenu: !pageVariables.showLogOutMenu });
    globalDispatcher({ type: ACTIONS.setLoggedIn, booleanPayload: false });
    localStorage.removeItem(LOCAL_STORAGE_KEYS.is_logged);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.user);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.permissions);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.stored_token);
  }

  useEffect(() => {
    let logged_in = localStorage.getItem(LOCAL_STORAGE_KEYS.is_logged);

    if (logged_in === 'true') {
      const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.user));
      const permissions = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.permissions))
      globalDispatcher({ type: ACTIONS.setLoggedIn, booleanPayload: true });
      globalDispatcher({ type: ACTIONS.setLoggedInUser, payload: user });
      globalDispatcher({ type: ACTIONS.setPermissions, payload: permissions });
    }
  }, []);

  const navigateToCompanyDetails = () => {
    setPageVariables({ ...pageVariables, showLogOutMenu: !pageVariables.showLogOutMenu });
  }

  return (
    <div className="EdTechApp">
      {loggedIn ? (
          <div>

          {/* <EdtechHeader
            pageVariables={pageVariables}
            setPageVariables={setPageVariables}
            userDropdown={userDropdown}
            sidenav={props.sidenav}
            networkStatus={networkStatus}
            setNetworkStatus={(status) => setNetworkStatus(status)}
            profileClicked={() => setPageVariables({ ...pageVariables, showLogOutMenu: !pageVariables.showLogOutMenu })}
            appsClicked={() => setPageVariables({ ...pageVariables, showAppsPop: !pageVariables.showAppsPop })}
          /> */}
          <div style={{display:'flex'}}>
          <EdtechSideBar sideNavBarRoutes={props.sidenav.navbar} profileClicked={() => logout()} />

          <div className="main-content">
          <Routes>
                    <Route path={ROUTES.home} element={<EdtechDashboardManagement />} />
                    <Route path={ROUTES.edtechDashboard} element={<EdtechDashboardManagement />} />
                    <Route path={ROUTES.candidate} element={<CandidateManagement />} />
                    <Route path={ROUTES.candidateDetails + '/:user_id'} element={<CandidateDetails />} />
                    <Route path={ROUTES.qp} element={<QPManagement />} />
                    <Route path={ROUTES.updateQPExam + '/:exam_id'} element={<EditQPExam />} />
                    <Route path={ROUTES.qpDetails + '/:exam_id'} element={<QPExamDetails />} />
                    <Route path={ROUTES.qpPaperDetails + '/:paper_id'} element={<QPPaperDetails />} />
                    <Route path={ROUTES.updatePaper + '/:paper_id'} element={<EditQPPaper />} />
                    <Route path={ROUTES.qpPaperDetails + '/:paper_id' + ROUTES.questionDetails + '/:question_id'} element={<QuestionViewer />} />
                    <Route path={ROUTES.qpPaperDetails + '/:paper_id' + "/modify-question" + '/:question_id'} element={<ModifyQuestion />} />
                    <Route path={ROUTES.edtechConfigs} element={<EdtechConfigsManagement />} />
                    <Route path={ROUTES.user} element={<UserCompany />} />

                    <Route path={ROUTES.blogs} element={<BlogsManagement />} />
                    {/* <Route path={ROUTES.notes} element={< />} /> */}
                    <Route path={ROUTES.questionTagging} element={<QuestionsTaggingManagement />} />


            </Routes>
          </div>
          </div>
          {pageVariables.showNotificationPop && user?.is_notification_enabled && (
            <NotificationAcknowledgement />
          )}
        
          </div>

        
      ) : (
        <Login />
      )}
      <Toaster position="top-right" />
    </div>
  );
}

export default EDTechApp;
