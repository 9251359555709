import React, { useEffect, useRef, useState } from "react";
import "./ModifyPlan.scss";
import { getExamPlanDetails, updateExamPlanDetails } from "../../../../../../APIServices/QP/ExamPlans";
import TextInput from "../../../../../../../components/Generic/TextInput/TextInput";
import FeatureInput from "../../../../../../../components/Generic/FeatureInput/FeatureInput";
import { Button } from "../../../../../../../components/Generic/Button/Button";
import Dropdown from "../../../../../../../components/Generic/Dropdown/Dropdown";

function ModifyPlan(props: {
  examId: any, planId: any,
  setShowModifyPopup: any,
}) {



  const form = {
    id: null,
    plan_name: "",
    plan_image_url: "",
    plan_features: [
    ],
    price: 0,
    save_upto_price: 0,
    validity_in_days: 1,
    discount_percent: 0,
  }
  const [formData, setFormData] = useState<any>(form);



  const [newPermission, setNewPermission] = useState("");


  const handleDeletePermission = (permission: any) => {
    let temp = formData.plan_features.filter((feature: any) => feature != permission)
    setFormData({ ...formData, plan_features: temp })
  }

  const handleAddPermission = () => {
    let temp = [...formData.plan_features, newPermission]
    setFormData({ ...formData, plan_features: temp })
    setNewPermission("")
  };



  const saveChanges = () => {
    updateExamPlanDetails(props.examId, props.planId, formData).then(res => {
      props.setShowModifyPopup()

    })
  }


  useEffect(() => {
    getExamPlanDetails(props.examId, props.planId).then(data => setFormData(data))
  }, [])

  return (
    <div className="modify-plan-container">
    <div className="heading">
      <h3>Edit Plan</h3>
      <div className="close" onClick={() => props.setShowModifyPopup()} > X </div>
    </div>
  
    <div className={'add-camera-form-row-1'}>

        <div className="image-container">
          <div className="image">
              <img
                src={formData.plan_image_url}
                alt="logo192.png"
                style={{ maxWidth: "4rem", maxHeight: "4rem" }}
              />
            
          </div>
        </div>
     

        <TextInput
            label={'Exam Plan'}
            placeholder={'0'}
            width={'90%'}
            value={formData.plan_name}
            isRequired={true}
            // isValid={validateFields}
            // showWarning={submitClicked}
          />
        </div>

  

    <div className="add-camera-form-row-2">
      <TextInput
            label={'Price'}
            placeholder={'0'}
            width={'40%'}
            onChange={(value) => setFormData({ ...formData, price: value })}
            value={formData.price}
            isRequired={true}
            // isValid={validateFields}
            // showWarning={submitClicked}
          />
      
  
      <TextInput
            label={'Validity'}
            placeholder={'Validity in days'}
            width={'40%'}
            onChange={(value) => setFormData({ ...formData, code: value })}
            value={formData.validity_in_days}
            isRequired={true}
            disabled

            // isValid={validateFields}
            // showWarning={submitClicked}
          />


    </div>



    <hr />
    <div className="content-access">
      <h3>Plan features</h3>
    
          {formData.plan_features.map( (access: any, index: number) => (
            <FeatureInput 
            label={''}
            placeholder={'Validity in days'}
            width={'90%'}
            onChange={(value) => setNewPermission( "value")}
            value={newPermission}
            isRequired={false}
            />
          ))}
    
    </div>
    <Button
          class='btn-container'
          bgcolor={'#02720d'}
          text={'Update Plan'}
          textcolor={'white'}
          width={''}
          onClick={() => {
            if (false) {
              console.log('Please fill all the required fields!');
              return;
            }
            else
              saveChanges()
              // setShowSettings(true)
          }}
    
    ></Button>
   
  </div>
  );
}

export default ModifyPlan;
