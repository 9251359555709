import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/Generic/Button/Button';
import { Filters } from '../../../components/Generic/Filters/Filters';
import { GenericTable } from '../../../components/Generic/Table';
import { ROUTES } from '../../../components/Routes/SideBarRoutes';
import './visitor-details.scss';
import { BlockVisitorCard } from './BlockVisitorPop/BlockVisitor';
import { toast } from 'sonner';
import { Preview } from '../../../components/Generic/ImagePreviewer/Preview';
import DetailsTabs from '../../../components/Generic/DetailsTab/DetailsTab';
import { getUserById } from '../../APIServices/UsersApi';
import CandidateDetailsTabs from './DetailTabs/QPExamDetailsTab';

export interface IVisitorDetailsProps {
}

export function CandidateDetails(props: IVisitorDetailsProps) {
  const navigate = useNavigate();
  const { user_id } = useParams();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(10);

  const handlePageChange = (newPage) => {
    setPage(newPage);

  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };



  const [pageVars, setPageVars] = useState({
    id: null,
    name: null,
    phone: null,
    role: null,
    profile_image_url: null,
    email: null,
    institute_code: null,
    is_active: true,
    gender: null,
    selectedActivity: false,
    openPopUp: false,
    openBlockPopUp: false,
  });
  const [tableVars, setTableVars] = useState({
    tableData: [],
    totalIn: 0,
    totalOut: 0,
    loading: false
  });
  const [loaders, setLoaders] = useState({
    updatingVisitors: false
  });


  const [view, setView] = React.useState({
    acknowledgement: null,
    showImage: false
  })

  const [viewIdCard, setViewIdCard] = React.useState({
    acknowledgement: null,
    showImage: false
  })


  const navigateBack = () => {
    navigate(ROUTES.candidate)
  }
  const fetchActivities = async (page, filters, query?) => {
    try {
      setTableVars({ ...tableVars, loading: true });
      let options = {

      }
      if (filters) {
        options = {
          ...options,
          start_date: filters.start_date,
          end_date: filters.end_date,
          activity: filters.activity,
          gate_number: filters.gate_number,
        }
      }
      options = {
        ...options,
        page: page,
        visitor_id: user_id,
        query: query
      }
     
  
    }
    catch (err) {
      setTableVars({ ...tableVars, loading: false });
    }
  }
  const getVisitorDetails = async () => {
    try {
      getUserById(user_id).then(res => {
        const { id, name, profile_image_url, phone, email, gender, role, institute_code } = res;
  
        // @ts-ignore
        setPageVars({ name, id, role, gender, profile_image_url, phone, email, institute_code })
      })

    }
    catch (err) {

    }
  }

  const blockVisitor = async () => {
    let is_active = pageVars.is_active;
    try {
      setLoaders({ ...loaders, updatingVisitors: true });
 
    }
    catch (err) {
      setLoaders({ ...loaders, updatingVisitors: false });
      toast.success(`Error: failed to  ${is_active ? 'blocked' : 'unblocked'} visitor!`)
    }
  }



  React.useEffect(() => {
    getVisitorDetails();
    setTableVars({ ...tableVars, loading: true });
    fetchActivities(page, null);
  }, []);


  React.useEffect(() => {
    fetchActivities(page, null);
  }, [page]);




  return (
    <div>
      <div className={'visitor-details-container'}>
        <div className={'visitor-details-left-panel'}>
          {/* <div style={{
            padding: '1rem',
            fontSize: '1.5rem',
            fontWeight: 'bold'
          }}>
            Visitor Profile Page
          </div> */}
          <div className={'visitor-details-left-panel-back-btn'}>
            <Button
              class='medium-btn-container'
              text={'< Back to candidates list'}
              textcolor={'dark-green'}
              bgcolor={'#09784433'}
              width={'fit-content'}
              onClick={navigateBack}
            />
          </div>
          <div className={'profile-image-name-section'}>
            <div
              className={'profile-image'}
            >
              <img style={{ width: '10rem', height: '10rem', objectFit: 'cover', borderRadius: '1rem' }}
                onClick={() => {
                  setView({ ...view, showImage: true })
                }}
                src={pageVars.profile_image_url}

              />
            </div>

            <div>

              <div className={'visitor-name'}>
                {pageVars.name}
              </div>
              {/* <div className={'visitor-status'}>
                <span className={pageVars.is_active ? 'status-active' : 'status-blocked'}> {pageVars.is_active ? 'Active' : 'Blocked'} </span>
              </div> */}
            </div>
          </div>
          {/* <div className={'pop-up-file'}>
            <div className={'pop-up-file-title'}>
              ID Card
            </div>

            <div>
              {pageVars.profile_image_url ?
                <div className={'pop-up-file-container'}>
                  <div className={'pop-up-file-details'}>
                    <div className={'pop-up-file-img'}>
                      <img
                        src={pageVars.profile_image_url}
                        alt={'pdf.svg'}
                      />
                    </div>

                  </div>
                  <div className={'pop-up-file-name-download'}>
                    <a href={'#'}
                      onClick={() => {
                        setViewIdCard({ ...viewIdCard, showImage: true })
                      }}
                      style={{ textDecoration: 'none' }}>
                      View ID Card
                    </a>
                  </div>
                </div> : 'N/A'}
            </div>

          </div> */}

          <div className={'personal-details-section'}>
            <div className={'personal-details-section-header'}>
              <div>Personal Details</div>
              <div className={'personal-details-section-header-btns'}>
                {/* <Button
                  class='medium-btn-container'
                  text={'Edit'}
                  textcolor={'white'}
                  bgcolor={'#4D4D33'}
                  width={'fil-content'}
                  onClick={() => navigate(ROUTES.updateVisitor + '/' + user_id)}
                />
                {pageVars.is_active ?
                  <Button
                    class='medium-btn-container'
                    text={'Block'}
                    textcolor={'white'}
                    bgcolor={'#7B0323'}
                    width={'fil-content'}
                    onClick={() => setPageVars({ ...pageVars, openBlockPopUp: !pageVars.openBlockPopUp })}
                  /> :
                  <Button
                    class='medium-btn-container'
                    text={'Unblock'}
                    textcolor={'white'}
                    bgcolor={'#4D4D33'}
                    width={'fil-content'}
                    onClick={() => setPageVars({ ...pageVars, openBlockPopUp: !pageVars.openBlockPopUp })}
                  />
                } */}
              </div>
            </div>

            <div className='personal-details'>
              <div className='personal-details-div'>
              <span>Full Name</span> : <span>{pageVars.name}</span>
              </div>
              <div className='personal-details-div'>
              <span>Phone no.</span> : <span>{pageVars.phone}</span>
              </div>
              <div className='personal-details-div'>
              <span>Gender</span> : <span>{pageVars.gender}</span>
              </div>
              <div className='personal-details-div'>
              <span>Email</span> : <span>{pageVars.email}</span>
              </div>
              <div className='personal-details-div'>
                {/* <span>Unit</span> : <span>{pageVars.unit}</span> */}
              </div>
              {/* <div className='personal-details-div'>
              <span>Institute</span> : <span>{pageVars.institute_code}</span>
              </div>
              <div className='personal-details-div'>
                <span>Registration time</span> : <span></span>
              </div> */}
     


            </div>
          </div>


          <div>

          </div>

        </div>
        <div className={'overview-container'}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CandidateDetailsTabs />
          </div>

        </div>





        {pageVars.openBlockPopUp ?
          <BlockVisitorCard
            block={pageVars.is_active}
            visitor={pageVars}
            close={() => setPageVars({ ...pageVars, openBlockPopUp: false })}
            confirm={blockVisitor}
            open={pageVars.openBlockPopUp}
            updating={loaders.updatingVisitors}
          /> : ''}

      </div>

    </div>
  );
}
